import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ContactUsForm = ({ onClose, extraClasses }) => {
  const [emailForm, setEmailForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    location: '',
    phoneNumber: '',
    subject: '',
    message: ''
  });

  ContactUsForm.propTypes = {
    onClose: PropTypes.any,
    extraClasses: PropTypes.shape({
      isPopUp: PropTypes.string,
      isClosable: PropTypes.bool,
      formStyling: PropTypes.string
    })
  }

  ContactUsForm.defaultProps = {
    extraClasses: {
      isPopUp: '',
      isClosable: '',
      formStyling: ''
    }
  }

  //Result if message was sent or not
  const [result, setResult] = useState('');

  //Status of while message is being sent
  const [status, setStatus] = useState('Submit');

  // Used to reset form back to black inputs
  function resetEmailForm() {
    setEmailForm({ 
      firstName: '',
      lastName: '',
      email: '',
      company: '',
      location: '',
      phoneNumber: '',
      subject: '',
      message: ''
    });
  }

  // Handle form input updating js variables 
  function handleEmailFormChange(event) {
    setEmailForm((prevEmailData) => {
        return {
            ...prevEmailData,
            [event.target.name]: event.target.value,
        };
    });

    // Set result variable
    if (result.length > 0) {
        setResult('');
    }
  }

  // Handle submission of form and sending API request
  const handleSubmit = async (e) => {
    setResult('');
    e.preventDefault();
    setStatus('Sending...');

    const { firstName, lastName, email, company, location, phoneNumber, subject, message } = e.target.elements;

    let emailBody = {
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      company: company.value,
      location: location.value,
      phoneNumber: phoneNumber.value,
      subject: subject.value,
      message: message.value,
    };

    // Send email using your preferred method (e.g., via API)
    try {
      // const response = await fetch('http://localhost:8080/send', { // If on localhost
      const response = await fetch('/sendContact.php', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(emailBody)
      })

      setStatus('Submit');
      let result = await response.json();

      if (result.status === 'success') {
        setResult('Message Sent!');
        resetEmailForm();
      } else if (result.status === 'fail') {
        alert('Uh oh! Message failed to send.');
      }
    } catch (error) {
        console.error(error);
        setStatus('Submit');
        setResult('Uh oh! Issues with submitting message.');
    }
  };

  return (
    <div className={classNames('calibri-300', extraClasses.isPopUp)}>
        <form onSubmit={handleSubmit} method='POST' className={classNames('bg-white duration-300 shadow-xl rounded px-8 pt-6 pb-8 lg:mx-8 mx-6 my-4', extraClasses.formStyling)}>
            <div className='flex justify-between my-4'>
              <h1 className='lg:text-4xl text-xl font-bold text-gray-700 py-2 whitespace-normal md:whitespace-nowrap'>Get in Touch with Mc<span>&#178;</span></h1>
              {extraClasses.isClosable ? <button className="bg-[#e8f0ff] hover:bg-[#ebf1fc] hover:scale-110 w-[50px] text-black font-semibold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline" onClick={onClose}>X</button> : null}
            </div>
            {/* Form for bigger screens */}
              {/* This row includes inputs for First Name, Last Name, Email */}
            <div className='lg:flex lg:space-x-4'>
              <div className="w-full lg:w-1/3 my-4">
                <label className="block text-gray-700 text-sm md:text-lg font-bold mb-2" htmlFor="firstName">First Name</label>
                <input
                    className="shadow-md hover:scale-105 duration-300 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="firstName"
                    type="text"
                    name="firstName"
                    value={emailForm.firstName}
                    onChange={handleEmailFormChange}
                />
              </div>
              <div className="w-full lg:w-1/3 my-4">
                <label className="block text-gray-700 text-sm md:text-lg font-bold mb-2" htmlFor="lastName">Last Name</label>
                <input
                    className="shadow-md hover:scale-105 duration-300 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="lastName"
                    type="text"
                    name="lastName"
                    value={emailForm.lastName}
                    onChange={handleEmailFormChange}
                />
              </div>
              <div className="w-full lg:w-1/3 my-4">
                <label className="block text-gray-700 text-sm md:text-lg font-bold mb-2" htmlFor="email">Email</label>
                <input
                    className="shadow-md hover:scale-105 duration-300 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="email"
                    type="email"
                    name="email"
                    value={emailForm.email}
                    onChange={handleEmailFormChange}
                />
              </div>
            </div>
            {/* This row includes inputs for Company, Location, Phone Number */}
            <div className='lg:flex lg:space-x-4'>
              <div className="w-full lg:w-1/3 my-4">
                <label className="block text-gray-700 text-sm md:text-lg font-bold mb-2" htmlFor="company">Company</label>
                <input
                    className="shadow-md hover:scale-105 duration-300 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="company"
                    type="text"
                    name="company"
                    value={emailForm.company}
                    onChange={handleEmailFormChange}
                />
              </div>
              <div className="w-full lg:w-1/3 my-4">
                <label className="block text-gray-700 text-sm md:text-lg font-bold mb-2" htmlFor="location">Location</label>
                <input
                    className="shadow-md hover:scale-105 duration-300 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="location"
                    type="text"
                    name="location"
                    value={emailForm.location}
                    onChange={handleEmailFormChange}
                />
              </div>
              <div className="w-full lg:w-1/3 my-4">
                <label className="block text-gray-700 text-sm md:text-lg font-bold mb-2" htmlFor="phoneNumber">Phone Number</label>
                <input
                    className="shadow-md hover:scale-105 duration-300 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="phoneNumber"
                    type="text"
                    name="phoneNumber"
                    value={emailForm.phoneNumber}
                    onChange={handleEmailFormChange}
                />
              </div>
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 text-sm md:text-lg font-bold mb-2" htmlFor="subject">Subject</label>
              <input
                  className="shadow-md hover:scale-105 duration-300 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="subject"
                  type="text"
                  name="subject"
                  value={emailForm.subject}
                  onChange={handleEmailFormChange}
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 text-sm md:text-lg font-bold mb-2" htmlFor="message">Message</label>
              <textarea
                  className="shadow-md hover:scale-105 duration-300 appearance-none border rounded w-full h-[8rem] py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline"
                  id="message"
                  type="text"
                  placeholder="(Max 300 characters)"
                  name="message"
                  value={emailForm.message}
                  onChange={handleEmailFormChange}
              />
            </div>
            <div className='flex justify-center'>
                <button className="bg-[#6197fa] hover:bg-[#367af7] w-[150px] text-black font-semibold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline" type="submit">{status}</button>
            </div>
            <div className='flex justify-center'>
              <h3 className='block text-gray-700 text-sm md:text-lg font-bold mb-2'>{result}</h3>
            </div>
        </form>
    </div>
  );
};

export default ContactUsForm;
