import React, { useEffect } from 'react'
import Navbar from '../Navbar'
import Header from '../Header'
import IT_DSPMain from './IT_DSPMain'
import Footer from '../Footer'

const IT_DSP = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  return (
    <div>
        <div dangerouslySetInnerHTML={{ __html: `<video style='position: fixed; width: 100%; height: 100%; object-fit: cover; z-index: 0;' src="${require('../../assets/IT-DSP/PulsingHeat.mp4')}" playsinline autoPlay loop muted autobuffer/>` }}></div>
        <div className='absolute top-0 left-0 z-2'>
            <Navbar />
            <Header title="IT-DSP&trade;" subTitle="INDUCTIVE-THERMAL DYNAMIC STRIPPING PROCESS&trade;" titleClasses="text-center lg:text-8xl md:text-7xl text-6xl xl:pt-60 lg:pt-40 pt-24 pb-8" subTitleClasses="xl:pb-48 pb-24" bgImage="bg-[url('./assets/IT-DSP/IT-DSPBannerBackground.jpg')]"/>
            <IT_DSPMain />
            <Footer />
        </div>
    </div>
  )
}

export default IT_DSP