import React from 'react'

const ContactUsLocation = (props) => {
  return (
    <div>
        <div className='col-span-1 flex flex-col'>
            <img src={ props.titleImg } alt="" className='xl:w-full w-3/5 mx-auto' />
            <div className='text-center xl:text-left border-2 border-[#4178a4] calibri-400 text-white bg-[#1d4e7e] opacity-80 px-4 py-8 h-full'>
                <h1 className='underline lg:text-3xl text-2xl mb-4'>{ props.location }</h1>
                <div className='my-4 lg:text-lg text-md'>
                    <h1>ADDRESS</h1>
                    <p className='text-[#a0acb8]'>{ props.address }</p>
                </div>
                <div className='my-4 lg:text-lg text-md'>
                    <h1>EMAIL</h1>
                    <p className='text-[#a0acb8]'>{ props.emailGeneral }</p>
                    <p className='text-[#a0acb8]'>{ props.emailMedia }</p>
                </div>
                {(props.phone != null && props.fax != null) && (
                    <div className='my-4 lg:text-lg text-md'>
                        <h1>TELEPHONE NUMBER</h1>
                        <p className='text-[#a0acb8]'>{ props.phone }</p>
                        <p className='text-[#a0acb8]'>{ props.fax }</p>
                    </div>
                )}
                <div className='mt-4 lg:text-lg text-md'>
                    <a className="hover:underline" href={ props.mapLink } target="_blank">
                        View on Map
                    </a>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ContactUsLocation